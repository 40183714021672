@import "../../variables.modules";

.pink {
  color: $pink;
}

.green {
  color: $green;
}

.skills {
  columns: 1;
  @media only screen and (min-width: 940px) {
    columns: 2;
  }

  li {
    margin: 0;
    line-height: 1.75;
  }
}

.terminalfont {
  font-size: medium;
  white-space: pre-line;
  line-height: 1.5;
}
